<template>
  <div class="feature-page">
    <section class="brings-bot-trading container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 bring-bot">
            <div class="bring-bot__title">
              <span class="white">{{ $t('feature_page.brings') }} </span>
              <span class="yellow">{{ $t('feature_page.bot') }} </span>
              <span class="white">{{ $t('feature_page.trade_life') }}</span>
            </div>
            <div class="bring-bot__description">
              <span>{{ $t('feature_page.description') }}</span>
            </div>
            <div class="bring-bot__form">
              <form>
                <div class="form-group button-item">
                  <input type="text" class="email" placeholder="Your email" />
                  <button class="connected">
                    {{ $t('feature_page.features') }}
                  </button>
                </div>
              </form>
              <div class="arrow-box">
                <div class="image">
                  <img src="~@assets/img/Arrow_1.png" alt="arrow" />
                </div>
              </div>
            </div>
            <div class="bring-bot__among">
              <span>{{ $t('feature_page.be_among') }} </span>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 navigation">
            <div class="navigation__menu">
              <ul class="menu_list">
                <li>{{ $t('feature_page.menu') }}</li>
                <li>{{ $t('feature_page.features') }}</li>
                <li>{{ $t('feature_page.account') }}</li>
                <li>{{ $t('feature_page.contact') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="detailed-features-block container-fluid">
      <div class="container">
        <div class="detailed-features__title">
          <div class="benefit">
            <span>{{ $t('feature_page.benefist') }}</span>
          </div>
          <div class="text">
            <span>{{ $t('feature_page.detailed') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 img-block">
            <img src="~@assets/img/matrix.png" alt="matrix" />
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders right">
            <div class="number">
              <span>01</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.matrix') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{ $t('feature_page.matrix_description.paragraph_1') }}
              </p>
              <p class="paraghraph_2">
                {{ $t('feature_page.matrix_description.paragraph_2') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders left">
            <div class="number">
              <span>02</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.matrix_price') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{ $t('feature_page.matrix_description_2.paragraph_1') }}
              </p>
              <p class="paraghraph_2">
                {{ $t('feature_page.matrix_description_2.paragraph_2') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 img-block img-nevro">
            <div class="row p-0 m-0 pt-lg-5 ps-lg-5 ms-lg-5">
              <div class="container p-0 m-0 ms-lg-5 ps-lg-5">
                <div class="col mt-5 pt-5 ps-4">
                  <h4 class="text-white ">Holding</h4>
                  <div class="row w-100">
                    <div class="col d-lg-flex">
                      <div class="img-eth">
                        <img src="~@assets/img/Ethereum.png" alt="" />
                      </div>
                      <div class="eth_desk ms-2">
                        <h5 class="text-white p-0 m-0">Etherium</h5>
                        <p class="text-white p-0 m-0">ETH</p>
                      </div>
                      <div class="graph ms-3 pt-2">
                        <img src="~@assets/img/Graph ETH.svg" alt="" />
                      </div>
                      <div class="ms-lg-5">
                        <h5 class="text-white p-0 m-0">$500</h5>
                      </div>
                    </div>
                  </div>
                  <div class="row w-100 mt-3">
                    <div class="col d-lg-flex">
                      <div class="img-btc">
                        <img src="~@assets/img/bitcoin_black.png" alt="" />
                      </div>
                      <div class="eth_desk ms-2">
                        <h5 class="text-white p-0 m-0">Bitcoin</h5>
                        <p class="text-white p-0 m-0">BTC</p>
                      </div>
                      <div class="graph ms-3 pt-2">
                        <img src="~@assets/img/Graph BTC.svg" alt="" />
                      </div>
                      <div class="ms-lg-5 ps-lg-2">
                        <h5 class="text-white p-0 m-0">$8543</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 img-block">
            <img src="~@assets/img/operation.png" alt="matrix" />
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders right">
            <div class="number">
              <span>03</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.operation') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{ $t('feature_page.operation_description.paragraph_1') }}
              </p>
              <p class="paraghraph_2">
                {{ $t('feature_page.operation_description.paragraph_2') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders left">
            <div class="number">
              <span>04</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.orders') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{ $t('feature_page.orders_description.paragraph_1') }}
              </p>
              <p class="paraghraph_2">
                {{ $t('feature_page.orders_description.paragraph_2') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 img-block">
            <img src="~@assets/img/volatility.png" alt="matrix" />
          </div>
          <div class="col-md-6 col-sm-6 img-block">
            <img src="~@assets/img/trading.png" alt="matrix" />
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders right">
            <div class="number">
              <span>05</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.trade_automatic') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{ $t('feature_page.trade_automatic_description.paragraph_1') }}
              </p>
              <p class="paraghraph_2">
                {{ $t('feature_page.trade_automatic_description.paragraph_2') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 matrix-orders left">
            <div class="number">
              <span>06</span>
            </div>
            <div class="title">
              <span>{{ $t('feature_page.operation_reports') }}</span>
            </div>
            <div class="description">
              <p class="paraghraph_1">
                {{
                  $t('feature_page.operation_reports_description.paragraph_1')
                }}
              </p>
              <p class="paraghraph_2">
                {{
                  $t('feature_page.operation_reports_description.paragraph_2')
                }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 img-block">
            <img src="~@assets/img/reports.png" alt="matrix" />
          </div>
        </div>
      </div>
    </section>

    <section class="art-container">
      <div class="image">
        <img src="~@assets/img/connections.png" alt="connection" />
      </div>
    </section>

    <section class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('feature_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
